import React, { useContext, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import { INPUT_FONT } from '../../util/fonts';
import { DataContext } from '../../Context/DataContext';
import { OnBlur, OnChange } from 'react-final-form-listeners';

const INPUT_CLASS = `${INPUT_FONT} appearance-none rounded-none w-full px-3 py-2 border border-gray-300 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10`;

export const InputField = ({
    placeholder,
    id,
    parse,
    autoFocus,
    onChangeId,
    onBlurId
}) => {
    const { focus } = useContext(DataContext);
    const ref = useRef();

    useEffect(() => {
        if (focus && autoFocus) {
            ref.current.focus();
        }
    }, [autoFocus, ref, focus]);

    return (
        <Field name={id} type="text" parse={parse && formatString(parse)}>
            {({ input, meta }) => (
                <>
                    <input
                        ref={ref}
                        {...input}
                        placeholder={parse || placeholder}
                        className={INPUT_CLASS}
                        autoComplete="off"
                    />
                    {onChangeId && (
                        <OnChange name={onChangeId}>
                            {(value, previous) => {
                                input.onChange(value);
                            }}
                        </OnChange>
                    )}
                    {onBlurId && (
                        <Field name={onBlurId}>
                            {({ input: blurInput, meta }) => (
                                <OnBlur name={id}>
                                    {() => {
                                        if (
                                            input.value &&
                                            input.value.trim() === '/'
                                        ) {
                                            input.onChange(blurInput.value);
                                        }
                                    }}
                                </OnBlur>
                            )}
                        </Field>
                    )}
                </>
            )}
        </Field>
    );
};
