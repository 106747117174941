import { FIELDS } from './fields';

const TRANSFORM = FIELDS.reduce((result, field) => {
    result[field.id] = field.transform;
    return result;
}, {});

export const transformValues = values => {
    Object.keys(values).forEach(key => {
        const transformer = TRANSFORM[key];
        if (transformer) {
            values[key] = transformer(values[key]);
        }
    });
};
