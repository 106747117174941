import React, { useContext } from 'react';
import { Form } from 'react-final-form';
import { POSITIONS } from '../../util/positions';
import { transformValues } from '../../util/transforms';
import { DataContext } from '../../Context/DataContext';
import { InputLayout } from '../../components/layout/InputLayout';

export const CkvForm = () => {
    const context = useContext(DataContext);

    return (
        <Form
            onSubmit={values => {
                transformValues(values);
                context.add(values);
            }}
            initialValues={context.initial}
            render={({ handleSubmit, form }) => (
                <form
                    onSubmit={event => {
                        handleSubmit(event);
                        setTimeout(() => {
                            form.reset();
                        }, 0);
                    }}
                >
                    <input type="submit" style={{ visibility: 'hidden' }} />
                    <div className="grid items-center grid-cols-4">
                        {POSITIONS.map(({ start, end }) => (
                            <InputLayout key={start} start={start} end={end} />
                        ))}
                    </div>
                </form>
            )}
        />
    );
};
