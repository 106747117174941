let x = 0;

export const POSITIONS = [
    { start: x, end: (x += 2) },
    { start: x, end: (x += 4) },
    { start: x, end: (x += 4) },
    { start: x, end: (x += 3) },
    { start: x, end: (x += 3) },
    { start: x, end: (x += 3) },
    { start: x, end: (x += 3) },
    { start: x, end: (x += 1) }
];
