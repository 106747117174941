export const replaceWeduwe = value => {
    if (value) {
        const result = value
            .replaceAll('e1 ', 'echtscheiding ')
            .replaceAll('g1 ', 'gescheiden van ')
            .replaceAll('r1 ', 'weduwnaar van ')
            .replaceAll('w1 ', 'weduwe van ')
            .replaceAll('h1 ', 'gehuwd ');

        return result
            .split(' ')
            .map(item => {
                if (!isNaN(item) && item.length === 8) {
                    return `${item.substr(0, 2)}-${item.substr(
                        2,
                        2
                    )}-${item.substr(4)}`;
                }
                return item;
            })
            .join(' ');
    }
    return value;
};
