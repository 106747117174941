export const INPUT_FONT =
    'text-sm font-medium text-gray-900 placeholder-gray-500';

export const BUTTON_FONT =
    'text-sm font-medium text-gray-500 hover:text-gray-900';

export const HEADER_FONT = 'text-left text-sm font-medium text-gray-500';

export const ROW_LAYOUT = 'border border-gray-300 rounded-md';
export const CELL_LAYOUT = 'whitespace-no-wrap';
export const CELL_FONT = '"text-sm leading-5 text-gray-900"';
export const SUB_CELL_FONT = 'text-sm leading-5 text-gray-500';
