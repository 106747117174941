export const capitalizeFirstLetter = value =>
    value ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : value;

export const capitalizeAllFirstLetter = value =>
    value
        ? value
              .split(' ')
              .map(text => capitalizeFirstLetter(text))
              .join(' ')
        : value;
