import React from 'react';
import { InputField } from '../input/InputField';
import { FIELDS } from '../../util/fields';

export const InputLayout = ({ start, end }) =>
    FIELDS.slice(start, end).map(field => (
        <div
            key={field.id}
            className={`p-1 col-span ${field.col || 'col-span-1'}`}
        >
            <InputField {...field} />
        </div>
    ));
