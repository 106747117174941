import React from 'react';
import { HEADER_FONT, ROW_LAYOUT } from '../../util/fonts';
import {
    ECHTGENOOT,
    ECHTGENOTE,
    GEMEENTE,
    MOEDER,
    NOTITIE,
    VADER
} from '../../util/const';

export const Header = () => (
    <tr className={ROW_LAYOUT}>
        <th className={HEADER_FONT}>{GEMEENTE}</th>
        <th className={HEADER_FONT}>{ECHTGENOOT}</th>
        <th className={HEADER_FONT}>{ECHTGENOTE}</th>
        <th className={HEADER_FONT}>{`${VADER} ${ECHTGENOOT}`}</th>
        <th className={HEADER_FONT}>{`${MOEDER} ${ECHTGENOOT}`}</th>
        <th className={HEADER_FONT}>{`${VADER} ${ECHTGENOTE}`}</th>
        <th className={HEADER_FONT}>{`${MOEDER} ${ECHTGENOTE}`}</th>
        <th className={HEADER_FONT}>{NOTITIE}</th>
        <th className={HEADER_FONT}>Opties</th>
    </tr>
);
