import React from 'react';
import { BUTTON_FONT } from '../../util/fonts';

export const Button = ({ title, onClick }) => (
    <button
        type="button"
        tabIndex={-1}
        className={`${BUTTON_FONT} p-2 border border-gray-300 rounded-md hover:outline-none active:outline-none hover:border-grey-800 transition duration-150 ease-in-out`}
        onClick={onClick}
    >
        {title}
    </button>
);
