import React, { useCallback, useEffect, useState } from 'react';
import { DataContext } from './DataContext';
import { STATE_KEY } from '../util/const';
import { getInitialValues } from '../util/initialValues';

const DEFAULT_EDIT_INDEX = -1;

export const DataMethodContext = ({ initData, children }) => {
    const [editIndex, setEditIndex] = useState(DEFAULT_EDIT_INDEX);
    const [data, setData] = useState(initData);
    const [focus, setFocus] = useState(undefined);
    const [initial, setInitial] = useState(getInitialValues(data[0]));

    const add = useCallback(
        item => {
            if (editIndex === DEFAULT_EDIT_INDEX) {
                setData([item, ...data]);
            } else {
                const clone = [...data];
                clone[editIndex] = item;
                setData(clone);
            }

            setFocus(new Date());
            setInitial(getInitialValues(item));
            setEditIndex(DEFAULT_EDIT_INDEX);
        },
        [data, setData, editIndex, setEditIndex, setInitial]
    );

    const clear = useCallback(() => {
        setData([]);
    }, [setData]);

    const edit = useCallback(
        index => {
            setFocus(new Date());
            setInitial(data[index]);
            setEditIndex(index);
        },
        [data, setEditIndex, setInitial]
    );

    const remove = useCallback(
        index => {
            const clone = [...data];
            clone.splice(index, 1);
            setData(clone);
            setFocus(new Date());
            setInitial(getInitialValues(clone[0]));
            setEditIndex(DEFAULT_EDIT_INDEX);
        },
        [data, setData, setEditIndex, setInitial]
    );

    useEffect(() => {
        localStorage.setItem(STATE_KEY, JSON.stringify(data));
    }, [data]);

    return (
        <DataContext.Provider
            value={{ data, add, clear, edit, remove, initial, focus }}
        >
            {children}
        </DataContext.Provider>
    );
};
