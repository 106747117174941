import React, { useContext } from 'react';
import { range } from 'd3-array';
import { DataContext } from '../../Context/DataContext';
import { Button } from '../Button/Button';
import { FIELDS } from '../../util/fields';
import {
    CELL_LAYOUT,
    CELL_FONT,
    SUB_CELL_FONT,
    ROW_LAYOUT
} from '../../util/fonts';

export const Row = ({ index, item }) => {
    const context = useContext(DataContext);

    let x = 0;

    return (
        <tr className={ROW_LAYOUT}>
            <td className={CELL_LAYOUT}>
                <div className={CELL_FONT}>{item[FIELDS[x].id]}</div>
                <div className={SUB_CELL_FONT}>{item[FIELDS[(x += 1)].id]}</div>
            </td>

            {range(0, 2).map(index => (
                <td key={index} className={CELL_LAYOUT}>
                    <div className={CELL_FONT}>
                        {item[FIELDS[(x += 1)].id]} {item[FIELDS[(x += 1)].id]}{' '}
                        {item[FIELDS[(x += 1)].id]}
                    </div>
                    <div className={SUB_CELL_FONT}>
                        {item[FIELDS[(x += 1)].id]}
                    </div>
                </td>
            ))}

            {range(0, 4).map(index => (
                <td key={index} className={CELL_LAYOUT}>
                    <div className={CELL_FONT}>
                        {item[FIELDS[(x += 1)].id]} {item[FIELDS[(x += 1)].id]}{' '}
                        {item[FIELDS[(x += 1)].id]}
                    </div>
                </td>
            ))}

            <td className={CELL_LAYOUT}>
                <div className={CELL_FONT}>{item[FIELDS[(x += 1)].id]}</div>
            </td>
            <td>
                <span className="pr-1">
                    <Button title="Edit" onClick={() => context.edit(index)} />
                </span>
                <Button
                    title="Verwijder"
                    onClick={() => context.remove(index)}
                />
            </td>
        </tr>
    );
};
