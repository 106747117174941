import React, { useContext } from 'react';
import { Button } from '../../components/Button/Button';
import { DataContext } from '../../Context/DataContext';
import { download } from '../../util/download';
import { HEADER_FONT } from '../../util/fonts';

export const CkvButtons = () => {
    const context = useContext(DataContext);

    return (
        <>
            <span className={`${HEADER_FONT} pl-1 pr-4`}>
                {`${context.data.length} Aantal`}
            </span>
            <span className="pr-4">
                <Button
                    title="Download csv"
                    onClick={() => download(context.data)}
                />
            </span>
            <Button
                title="Alles verwijderen"
                onClick={() => {
                    if (window.confirm('Alles verwijderen?')) {
                        context.clear();
                    }
                }}
            />
        </>
    );
};
