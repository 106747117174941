import { FIELDS } from './fields';
import { ECHTGENOOT, ECHTGENOTE, GEBOORTEPLAATS, GEMEENTE } from './const';

const INITIAL_VALUES = FIELDS.reduce((result, field) => {
    result[field.id] = '';
    return result;
}, {});

export const getInitialValues = lastItem => {
    const init = { ...INITIAL_VALUES };
    if (lastItem && lastItem[GEMEENTE]) {
        init[GEMEENTE] = lastItem[GEMEENTE];
        init[`${ECHTGENOOT}_${GEBOORTEPLAATS}`] = lastItem[GEMEENTE];
        init[`${ECHTGENOTE}_${GEBOORTEPLAATS}`] = lastItem[GEMEENTE];
    }
    return init;
};
