import {
    ACHTERNAAM,
    DATUM,
    ECHTGENOOT,
    ECHTGENOTE,
    FORMAT_DATE,
    GEBOORTEPLAATS,
    GEMEENTE,
    MOEDER,
    NOTITIE,
    TUSSENVOEGSEL,
    VADER,
    VOORNAAM
} from './const';
import {
    capitalizeAllFirstLetter,
    capitalizeFirstLetter
} from './capitalizeFirstLetter';
import {
    getDownloadDate,
    getDownloadValue,
    getDownloadValues
} from './download';
import { replaceWeduwe } from './replaceWeduwe';

export const FIELDS = [
    {
        id: GEMEENTE,
        placeholder: GEMEENTE,
        autoFocus: true,
        col: 'col-span-3',
        transform: capitalizeFirstLetter,
        download: getDownloadValue
    },
    {
        id: DATUM,
        placeholder: DATUM,
        parse: FORMAT_DATE,
        download: getDownloadDate
    },
    {
        id: `${ECHTGENOOT}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${ECHTGENOOT}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL
    },
    {
        id: `${ECHTGENOOT}_${ACHTERNAAM}`,
        placeholder: `${ECHTGENOOT} ${ACHTERNAAM}`,
        transform: capitalizeFirstLetter,
        download: (field, item) =>
            getDownloadValues(field, item, `${ECHTGENOOT}_${TUSSENVOEGSEL}`)
    },
    {
        id: `${ECHTGENOOT}_${GEBOORTEPLAATS}`,
        placeholder: GEBOORTEPLAATS,
        transform: capitalizeFirstLetter,
        onChangeId: GEMEENTE,
        download: getDownloadValue
    },
    {
        id: `${ECHTGENOTE}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${ECHTGENOTE}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL
    },
    {
        id: `${ECHTGENOTE}_${ACHTERNAAM}`,
        placeholder: `${ECHTGENOTE} ${ACHTERNAAM}`,
        transform: capitalizeFirstLetter,
        onBlurId: `${ECHTGENOOT}_${ACHTERNAAM}`,
        download: (field, item) =>
            getDownloadValues(field, item, `${ECHTGENOTE}_${TUSSENVOEGSEL}`)
    },
    {
        id: `${ECHTGENOTE}_${GEBOORTEPLAATS}`,
        placeholder: GEBOORTEPLAATS,
        transform: capitalizeFirstLetter,
        onChangeId: GEMEENTE,
        onBlurId: `${ECHTGENOOT}_${GEBOORTEPLAATS}`,
        download: getDownloadValue
    },
    {
        id: `${VADER}_${ECHTGENOOT}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${VADER}_${ECHTGENOOT}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL,
        onChangeId: `${ECHTGENOOT}_${TUSSENVOEGSEL}`
    },
    {
        id: `${VADER}_${ECHTGENOOT}_${ACHTERNAAM}`,
        placeholder: `${VADER} ${ECHTGENOOT} ${ACHTERNAAM}`,
        col: 'col-span-2',
        transform: capitalizeFirstLetter,
        onChangeId: `${ECHTGENOOT}_${ACHTERNAAM}`,
        download: (field, item) =>
            getDownloadValues(
                field,
                item,
                `${VADER}_${ECHTGENOOT}_${TUSSENVOEGSEL}`
            )
    },
    {
        id: `${MOEDER}_${ECHTGENOOT}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${MOEDER}_${ECHTGENOOT}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL
    },
    {
        id: `${MOEDER}_${ECHTGENOOT}_${ACHTERNAAM}`,
        placeholder: `${MOEDER} ${ECHTGENOOT} ${ACHTERNAAM}`,
        col: 'col-span-2',
        transform: capitalizeFirstLetter,
        onBlurId: `${VADER}_${ECHTGENOOT}_${ACHTERNAAM}`,
        download: (field, item) =>
            getDownloadValues(
                field,
                item,
                `${MOEDER}_${ECHTGENOOT}_${TUSSENVOEGSEL}`
            )
    },
    {
        id: `${VADER}_${ECHTGENOTE}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${VADER}_${ECHTGENOTE}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL,
        onChangeId: `${ECHTGENOTE}_${TUSSENVOEGSEL}`
    },
    {
        id: `${VADER}_${ECHTGENOTE}_${ACHTERNAAM}`,
        placeholder: `${VADER} ${ECHTGENOTE} ${ACHTERNAAM}`,
        col: 'col-span-2',
        transform: capitalizeFirstLetter,
        onChangeId: `${ECHTGENOTE}_${ACHTERNAAM}`,
        download: (field, item) =>
            getDownloadValues(
                field,
                item,
                `${VADER}_${ECHTGENOTE}_${TUSSENVOEGSEL}`
            )
    },
    {
        id: `${MOEDER}_${ECHTGENOTE}_${VOORNAAM}`,
        placeholder: VOORNAAM,
        transform: capitalizeAllFirstLetter,
        download: getDownloadValue
    },
    {
        id: `${MOEDER}_${ECHTGENOTE}_${TUSSENVOEGSEL}`,
        placeholder: TUSSENVOEGSEL
    },
    {
        id: `${MOEDER}_${ECHTGENOTE}_${ACHTERNAAM}`,
        placeholder: `${MOEDER} ${ECHTGENOTE} ${ACHTERNAAM}`,
        col: 'col-span-2',
        transform: capitalizeFirstLetter,
        onBlurId: `${VADER}_${ECHTGENOTE}_${ACHTERNAAM}`,
        download: (field, item) =>
            getDownloadValues(
                field,
                item,
                `${MOEDER}_${ECHTGENOTE}_${TUSSENVOEGSEL}`
            )
    },
    {
        id: NOTITIE,
        placeholder: `${NOTITIE}: [e1 ]= echtscheiding [g1 ] = gescheiden van [r1 ]= weduwnaar van [w1 ] = weduwe van [h1 ] = gehuwd`,
        col: 'col-span-4',
        transform: replaceWeduwe,
        download: getDownloadValue
    }
];
