import { HEADER } from './const';
import { FIELDS } from './fields';

export const SEPARATOR = `;`;

export const getDownloadValue = (field, item) =>
    item[field.id] ? item[field.id].trim() : '';

export const getDownloadValues = (field, item, id) => {
    const tussenvoegsel = getDownloadValue({ id }, item);
    const achternaam = getDownloadValue(field, item);
    return `${tussenvoegsel ? `${tussenvoegsel} ` : ''}${achternaam}`;
};

export const getDownloadDate = (field, item) => {
    const split = getDownloadValue(field, item).split('-');
    while (split.length < 3) {
        split.push('');
    }
    if (split.length === 3) {
        return split.join(SEPARATOR);
    }
};

const downloadFile = (year, data) => {
    const link = document.createElement('a');
    link.download = `${year}.csv`;
    link.href = data;
    link.click();
};

export const download = data => {
    if (data.length === 0) {
        return;
    }

    let year = getDownloadDate(FIELDS[1], data[0]).split(SEPARATOR)[2];
    if (year === '') {
        year = new Date().getFullYear();
    }

    const lines = data
        .map(item => {
            return FIELDS.reduce((line, field) => {
                const { download } = field;
                if (download) {
                    const text = download(field, item);
                    return line === undefined
                        ? text
                        : `${line}${SEPARATOR}${text}`;
                }
                return line;
            }, undefined);
        })
        .join('\n');

    downloadFile(
        year,
        `data:text/csv;charset=UTF-8,encodeURIComponent(${HEADER}${lines}`
    );
};
