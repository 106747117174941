import React, { useContext } from 'react';
import { Header } from '../../components/table/Header';
import { Row } from '../../components/table/Row';
import { DataContext } from '../../Context/DataContext';

export const CkvTable = () => {
    const context = useContext(DataContext);

    return (
        <table className="min-w-full">
            <thead>
                <Header />
            </thead>
            <tbody>
                {context.data.map((item, index) => (
                    <Row key={index} index={index} item={item} />
                ))}
            </tbody>
        </table>
    );
};
