import React, { useEffect, useState } from 'react';
import { STATE_KEY } from './util/const';
import { DataMethodContext } from './Context/DataMethodContext';
import { CkvButtons } from './compositions/buttons/Buttons';
import { CkvForm } from './compositions/Input/CkvForm';
import { CkvTable } from './compositions/table/CkvTable';

export const App = () => {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        const items = localStorage.getItem(STATE_KEY);

        if (items) {
            setData(JSON.parse(items));
        } else {
            setData([]);
        }
    }, [setData]);

    if (data === undefined) {
        return null;
    }

    return (
        <DataMethodContext initData={data}>
            <div className="m-2">
                <div className="my-5">
                    <CkvForm />
                </div>
                <div className="my-5">
                    <CkvButtons />
                </div>
                <div>
                    <CkvTable />
                </div>
            </div>
        </DataMethodContext>
    );
};
